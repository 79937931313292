import React, { useEffect, useState } from 'react'
import { IonContent, IonPage, IonImg, useIonRouter, useIonAlert, IonLoading } from '@ionic/react'
import { useParams } from 'react-router-dom'
import Profile from '../../components/Profile'
import styles from './MyProfile.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import { Geolocation as CapacitorGeolocation } from '@capacitor/geolocation'
import { getOwnProfile, getProfile } from './services'
import NavBar from '../../components/Navbar/NavBar'
import ToggleMenu from '../../components/ToggleMenu'
import { dislikeProfile, likeProfile } from '../Search/services'
import IonPhotoViewer from '@codesyntax/ionic-react-photo-viewer'
import MatchModal from '../../components/MatchModal'
import { ProfileStub } from '../../util/types'
import { updateUser } from '../Agreement/services'
import { handleIfValidInteraction } from '../../util/a11yHelper'
import { IS_CAPACITOR } from '../../consts'
import { useRevenueCatContext } from '../../contexts/RevenueCatContext'

const updateLocation = async () => {
  try {
    let allowed = true

    if (IS_CAPACITOR) {
      const { location } = await CapacitorGeolocation.requestPermissions()

      allowed = location === 'granted'
    }

    if (allowed) {
      const { coords } = await CapacitorGeolocation.getCurrentPosition()

      console.log('[geolocation] coords', coords)

      if (coords) {
        await updateUser({ latitude: coords.latitude, longitude: coords.longitude })
      }
    }
  } catch (e) {
    console.error('[geolocation] error', e)
  }
}

const MyProfile: React.FC = () => {
  const { parseOwnProfile } = useRevenueCatContext()
  const router = useIonRouter()
  const params: any = useParams()
  const [presentAlert] = useIonAlert()
  const [profileInfo, setProfileInfo] = useState<any>({})
  const [allowEdit, setAllowEdit] = useState(true)
  const [loading, setLoading] = useState(true)
  const [matchedProfile, setMatchedProfile] = useState<ProfileStub | null>(null)

  useEffect(() => {
    getProfileInformation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  useEffect(() => {
    updateLocation()
  }, [])

  const isOwnProfile = params.id === 'own'
  const userDetailsString = localStorage.getItem('userDetails')
  const loggedInUserDetails = userDetailsString ? JSON.parse(userDetailsString) : null

  const routeToMoreAbout = (e) => {
    handleIfValidInteraction(e, () => {
      router.push('/edit-profile?tab=moreAbout')
    })
  }

  const getProfileInformation = async () => {
    setLoading(true)
    let resp
    if ((isOwnProfile && loggedInUserDetails) || loggedInUserDetails.id === params.id) {
      resp = await getOwnProfile()
      parseOwnProfile(resp.data)
      setAllowEdit(true)
      if (resp?.data?.UserPhotos && resp.data.UserPhotos.length > 0) {
        localStorage.removeItem('userPhoto')
        let photos = resp.data.UserPhotos.filter((item) => item.photo_url)
        localStorage.setItem('userPhoto', photos[0]['photo_url'])
      }
    } else {
      resp = await getProfile(params)
      setAllowEdit(false)
    }

    if (resp.data) {
      setProfileInfo(resp.data)
    }

    setLoading(false)
  }

  const swiped = (direction: 'left' | 'right') => {
    if (direction === 'right') {
      likeProfile({ to_user_id: profileInfo.id }).then((res: any) => {
        if (res.data.match) {
          setMatchedProfile(profileInfo)
        } else {
          router.push('/home/search')
        }
      })
    } else {
      if (profileInfo.is_match) {
        presentAlert({
          message: `Are you sure you want to unmatch with ${profileInfo.first_name}?`,
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              cssClass: styles.cancelBtn
            },
            {
              text: 'Yes',
              role: 'confirm',
              cssClass: styles.confirmBtn,
              handler: async () => {
                await dislikeProfile({ to_user_id: profileInfo.id })
                router.push('/home/search')
              }
            }
          ],
          cssClass: 'logOutAlert'
        })
      } else {
        dislikeProfile({ to_user_id: profileInfo.id }).then(() => {
          if (params?.view_type === 'like') {
            router.push('/home/my_likes')
          } else {
            router.push('/home/search')
          }
        })
      }
    }
  }

  return (
    <>
      <ToggleMenu contentId='profile-page' />
      <IonPage id='profile-page'>
        <NavBar contentId='profile-page' />
        <IonContent>
          {loading ? (
            <IonLoading isOpen={true} />
          ) : (
            <div>
              <div className={styles.imgsecion}>
                <Swiper
                  navigation={true}
                  pagination={true}
                  modules={[Navigation, Pagination]}
                  className={styles.mySwiper}
                >
                  {profileInfo.UserPhotos &&
                    profileInfo.UserPhotos.filter((item: any) => item.photo_url).map((imgs: any, index: number) => {
                      return (
                        <SwiperSlide key={`profimage${index}`}>
                          <div className={styles.content}>
                            <div className={styles.imageSec}>
                              <IonPhotoViewer
                                src={imgs.photo_url}
                                title={`${profileInfo.first_name}`}
                              >
                                <IonImg
                                  className={styles.profileImg}
                                  src={imgs.photo_url}
                                  alt='dp'
                                />
                                {imgs.description ? <p className={styles.description}>{imgs.description}</p> : null}
                              </IonPhotoViewer>
                            </div>
                          </div>
                        </SwiperSlide>
                      )
                    })}
                </Swiper>
                {!isOwnProfile && loggedInUserDetails.id !== params.id && profileInfo?.account_status !== 'DELETED' && (
                  <div className={`flex ion-justify-content-between ${styles.likeButtonGrp}`}>
                    <div
                      role='button'
                      tabIndex={0}
                      onClick={() => swiped('left')}
                      onTouchStart={() => swiped('left')}
                      onKeyUp={(e) =>
                        handleIfValidInteraction(e, () => {
                          swiped('left')
                        })
                      }
                      className={styles.swipeButton}
                    >
                      <IonImg
                        src='assets/images/skipbtn.png'
                        alt='Skip this profile'
                        className={styles.image}
                      />
                    </div>
                    <div
                      role='button'
                      tabIndex={0}
                      onClick={() => swiped('right')}
                      onTouchStart={() => swiped('right')}
                      onKeyUp={(e) =>
                        handleIfValidInteraction(e, () => {
                          swiped('right')
                        })
                      }
                      className={styles.swipeButton}
                    >
                      <IonImg
                        src='assets/images/likebtn.png'
                        alt='Like this profile'
                        className={styles.image}
                      />
                    </div>
                    <MatchModal
                      matchedProfile={matchedProfile}
                      setMatchedProfile={setMatchedProfile}
                    />
                  </div>
                )}
                {allowEdit && (
                  <div
                    tabIndex={0}
                    role='link'
                    className={styles.editlogo}
                    onClick={routeToMoreAbout}
                    onKeyUp={routeToMoreAbout}
                  >
                    <IonImg
                      src='/assets/images/pencil.png'
                      alt='Edit My Profile'
                    ></IonImg>
                  </div>
                )}
              </div>
              <Profile
                profile={{}}
                profileInfo={profileInfo}
                isEditable={allowEdit}
              />
            </div>
          )}
        </IonContent>
      </IonPage>
    </>
  )
}

export default MyProfile
